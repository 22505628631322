import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagerService } from 'src/app/services/manager.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { SetupService } from 'src/app/services/setup.service';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {
  invoices: any; filtered: any[] = []; 
  response: any; search: string =''; invoice: any; st: any; id = 0; exp: any; upload:any[]=[];
  payment: any[] = []; total = 0; merchants: any[] = []; rpt: any;
  type: any[] = ["RECEIVE", "SEND"]; view: boolean = false; report: boolean = false; tran: any;
  private _searchTitle: string;

  get searchTitle(): string {
    return this._searchTitle;
  }

  set searchTitle(value: string) {
    this._searchTitle = value;
    this.search = this._searchTitle;
  }

  constructor(private api: ApiService, private route: ActivatedRoute, private use: NavComponent, public ext: ExportService) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      // 
      this.getInvoice(page,limit,search);
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", code: null }
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }


  getInvoice(page=1,limit=12,search='') {
    this.api.get(`Invoice/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any)  => {
      this.filtered = data.data; this.response = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Invoice List');
    });
  }

  getTotal(trans) {
    
    this.total = 0;
    trans.forEach((e: any) => {
      if (e.code === 1) {
        this.total += e.amount;
      }
    });
    return this.total;
  }

  generate() {
    if (this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('Invoices/Report', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; this.getTotal(data);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Invoice List');
    });
  }

  Update() {
    this.api.put('Invoice/Put/', this.rpt).subscribe((data: any) => {
      this.use.showMsg('success', 'Debt Request Trizzered', 'Debt has been requested successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Account List Fail', 'Unable to Fetch Account List');
    });
  }

  disable(val) {
    val.status = "Cancel";
    this.api.put('Invoice/'+val.invoiceid, val).subscribe((data: any) => {
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Invoice Canceling Failed', 'Unable to Cancel Invoice');
    });
  }

  searchResult(searchString: string) {
    return this.invoices.filter(a => a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.code.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

  read(event) {
    this.ext.readExcel(event.target.files[0]);
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'invoices');
      this.use.showMsg('success', 'Invoices Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Invoices Export Fail', 'There is no Item Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, CODE: e.code, NAME: e.name, MOBILE: e.mobile, EMAIL: e.email, OTHER: e.other,
        ACTIVE: e.active, CREATEDDATE: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }

}
