import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { send } from 'q';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  @ViewChild('alertForm') @ViewChild('singleForm') public any: NgForm;
  send: any; sing: any; date: any; save: any; edit: boolean; response:any; alerts:any[]=[]
  cust: any[] = []; get: any; res: any; 
  types:any[]=["{name} ","{customer} ","{code} ","{number} ","{account_type} ","{account_number} ","{currency} ","{amount} ","{balance} ","{charge} ","{frequency} ","{date} ","{tranid} ","{reference} ","{merchant} "]
  constructor(private apis: ApiService, private api: SetupService, public use: NavComponent) { }

  ngOnInit() {
    // console.log(this.use.active);
    this.date = this.use.date;
    this.formReset();
    // this.getDefault();
    this.getAllAlerts();
  }

  getAllAlerts(page=1,limit=120,search='') {
    this.apis.get(`Alert/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.response = data; 
      this.alerts = data.data; 
      console.log(data)
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Api Key List');
    });
  }

  getDefault() {
    this.api.getSmslogsDistinct().subscribe((data: any) => {
      this.cust = data;
      // this.use.showMsg('success', 'Sms Phone Numbers', 'Sms Status');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Get Mobile Numbers');
    });
  }

  postSingle() {
    this.api.getCode(this.sing.mobile).subscribe((data: any) => {
      this.sing = data;
      this.use.showMsg('success', 'Sms Sented', 'Sms Status' + data.response);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Sending Fail', 'Unable to Send Sms ' + err);
    });
  }

  postSms() {
    if (this.save.alertid > 0) {
      this.api.putAlert(this.save.alertid, this.save).subscribe((data: any) => {
        this.send = data.data; this.formReset(); this.getAllAlerts();
        this.use.showMsg('success', 'Alert Updated', 'Alert Has been Updated Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Alert Fail', 'Unable to Update Sms Alert');
      });
    } else {
      this.api.postAlert(this.save).subscribe((data: any) => {
        this.send = data.data; this.formReset(); this.getAllAlerts();
        this.use.showMsg('success', 'Alert Saved', 'Alert Has been Saved Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg('error', 'Alert Fail', 'Unable to Save Sms Alert');
      });
    }
  }

  addToMessage(val) {
    this.save.message +=val;
  }

  getSms(value) {
    this.api.getAlertByType(value).subscribe((data: any) => {
      if(data.data) this.save = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'No Saved Alert message for ' + value);
    });
  }

  getAllC() {
    this.cust.forEach((e: any) => {
      if (e.mobile.length > 9) {
        this.send.mobile += e.mobile + ',';
        this.send.count += 1;
      }
    });
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.send = { mobile: '', message: '', option: 'All', count: 0,
        userid: this.use.active.id, date: new Date() };
      this.sing = { tcode: '', mobile: '', message: '', code: '',
        userid: this.use.active.id, date: new Date() };
      this.save = {
        alertid: 0, type: null, message: '', name: false, auto: true,
        userid: this.use.active.id, date: new Date()
      };
    }
  }

}
