import { Injectable } from '@angular/core';
// import { AngularFirestore } from '@angular/fire/firestore';
// import { AngularFireDatabase } from '@angular/fire/database';
// import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = environment.apiUrl;
  apis = environment.api;
  private isLoading = new Subject<boolean>();
  public Loading = this.isLoading.asObservable();
  activeuser: any;
  public active: any;
  userid: any;

  constructor(
    private http: HttpClient, public toast: ToastrService, public jwtHelper: JwtHelperService, private router: Router, private route: ActivatedRoute) { }

  show() {
    this.isLoading.next(true);
    // this.Loading = true;
  }

  hide() {
    this.isLoading.next(false);
    // this.Loading = false;
  }

  showMsg(type, title, message) {
    setTimeout(()=>{
      if (type === 'success') {
        this.toast.success(message, title);
      } else if (type === 'warning') {
        this.toast.warning(message, title);
      } else if (type === 'error') {
        this.toast.error(message, title);
      }
    }, 10);
  }

  user() {
    // console.log(this.jwtHelper.getTokenExpirationDate());
    // console.log(this.jwtHelper.tokenGetter());
    return this.jwtHelper.decodeToken(this.jwtHelper.tokenGetter());
  }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'upload/' + type, fd);
  }

  getlogs(route): Observable<any> {
    return this.http.get(this.apis + route);
  }

  postdata(rout, data): Observable<any> {
    return this.http.post(rout, JSON.stringify(data));
  }

  get(route): Observable<any> {
    return this.http.get(this.api + route);
  }

  post(rout, data): Observable<any> {
    return this.http.post(this.api + rout, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    return this.http.delete(this.api + rout);
  }

  find(rout): Observable<any> {
    return this.http.get(this.api + rout);
  }
  
  Reset(email): Observable<any> {
    return this.http.get(this.api + 'auth/reset/' + email);
  }

  updateUrl(page=1,limit=10,search='') {
    const params = { page: page, limit: limit, search: search };
    this.router.navigate([],{
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
      skipLocationChange: false
    }).finally(()=> {

    });
  }

}
