import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/services/manager.service';
import { SetupService } from 'src/app/services/setup.service';
import { ExportService } from 'src/app/services/export.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

declare var $: any;

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.sass']
})
export class PaymentsComponent implements OnInit {

  payment: any[] = []; filtered: any[] = []; total = 0; merchants: any[] = []; rpt: any;
  id: number; exp: any[]; balance = 0; view: boolean = false; report: boolean = false; tran: any; search = '';
  type: any[] = ["RECEIVE", "SEND"]; response:any; 
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }
  constructor(private api: ApiService, private set: SetupService, private ext: ExportService, private use: NavComponent, private route: ActivatedRoute) { }

  ngOnInit() {
    // $.getScript('assets/js/plugins/footable/footable.all.min.js');
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllPayment(page,limit,search);
    });
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? 0:this.use.active.merchantid, type: null, start: '', end: '', settled: null, code: null }
    this.getMerchants();
  }

  getMerchants() {
    this.api.get('merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },(err: HttpErrorResponse) => {
        this.use.showMsg( "error", "Fetching Fail", "Unable to Fetch Merchants List");
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getMerchant(val) {
    this.api.get("merchant/" + val).subscribe((data: any) => {
      this.balance = data.balance;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant Balance');
    });
  }

  getAllPayment(page=1,limit=12,search='') { 
    this.api.get(`Payment?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      // this.payment = data; this.response = data; 
      this.payment = data; this.response = data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  deletePayment(val) {
    this.api.get('Payment/Delete/'+val.paymentid).subscribe((data: any) => {
      this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  update() {
    // this.api.get('Payment/Put/'+ this.rpt.merchantid).subscribe((data: any) => {
    // }, (err: HttpErrorResponse) => {
    //   this.use.showMsg('error', 'Fetching Fail', err.message);
    // });
  }

  getTranStatus(val) {
    this.api.get('Integration/v1/GetTrans?order_id='+val.tranid).subscribe((data: any) => {
      // this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  refresh() {
    this.id = 1; this.exp = []; var count = 0
    this.api.postdata('https://api.cspay.app/api/gh/getstatus', this.filtered).subscribe((data: any) => {
      // this.getAllPayment();
      this.use.showMsg('success', 'Transactions status', 'successfully schedule TranStatus');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
    // this.filtered.forEach((e: any) => {
    //   setTimeout(() => { this.getTranStatus(e) }, count * 1000);
    // });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  checkPayment(val) {
    const code = val.transaction_no;
    if(!code || code == 0) { code == val.tranid }
    this.api.get('PayNow/Confirm/'+ code).subscribe((data: any) => {
      this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  generate() {
    if(this.rpt.type == "SEND") { this.rpt.settled = true; }
    this.api.post('Payment/Reports', this.rpt).subscribe((data: any) => {
      this.payment = data; this.filtered = data; console.log(JSON.stringify(data));
      if(this.rpt.merchantid) this.getMerchant(this.rpt.merchantid);
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, 'payments');
      this.use.showMsg('success', 'Transactions Exported', 'Check Downloaded Excel File For Details');
    } else {
      this.use.showMsg('error', 'Transactions Export Fail', 'There is no Transactions Data to Export');
    }
  }

  format() {
    this.id = 1; this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id, TRANID: e.tranid, ACCOUNT: e.account, TYPE: e.type, MOBILE: e.mobile, NETWORK: e.network, SERVICE: e.service, AMOUNT: e.amount, TRANSACTION_NO: e.transaction_no, TRANSACTION_REF: e.transaction_ref, REFERENCE: e.reference, RESPONSE: e.response, CREATEDDATE: e.date, UPDATED: e.date
      };
      this.exp.push(ed); this.id++;
    });
    return this.exp;
  }
  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.payment.filter(a => a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.mobile.indexOf(searchString) !== -1 || a.reference.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.source.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.network.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
