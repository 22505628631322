import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { SetupService } from 'src/app/services/setup.service';
import { NavComponent } from 'src/app/traits/nav/nav.component';
import { ApiService } from 'src/app/services/api.service';
import { ExportService } from 'src/app/services/export.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setup-category',
  templateUrl: './setup-category.component.html',
  styleUrls: ['./setup-category.component.sass']
})
export class SetupCategoryComponent implements OnInit {
  @ViewChild('sessionForm') public any: NgForm;
  category: any; add: Boolean; edit: Boolean = false; response: any; res: any; list: boolean;
  categories: any[] = []; industries: any[] = []; user: any;

  constructor(private route: ActivatedRoute, private _use: NavComponent, private api: ApiService, private _exp: ExportService) { }

  ngOnInit() {
    this.user = this._use.active;
    this.formReset();
    // this.getAllCharge();
    this.getIndustries();
    this.route.queryParams.subscribe((data)=> {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 8;
      const search = data.search ? data.search : '';
      // 
      this.getAllCategories(page,limit,search);
    })
  }

  getAllCategories(page=1,limit=10,search='') {
    this.api.get(`Category/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.categories = data.data; this.response = data;
      this._use.showMsg('success', 'Category List', 'Fetched Category list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Category List'); });
  }

  getIndustries(page=1,limit=100,search='') {
    this.api.get(`Industry/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.industries = data.data;
      },
      (err: HttpErrorResponse) => {
        this._use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Industries List"
        );
      }
    );
  }

  editCategory(data) {
    this.category = data;
    this.edit = true;
  }

  delCategory(dat) {
    this.api.delete('Category/Delete/'+dat.chargeid).subscribe((data: any) => {
      this.res = data; this.getAllCategories();
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Delete Fail', 'Unable to Delete Category'); 
    });
  }

  saveCategory() {
    if(this.category.id) {
      this.putCategory();
    } else {
      this.postCategory();
    }
  }

  postCategory() {
    this.api.post('Category/Post', this.category).subscribe((data: any) => {
      this.getAllCategories(); this.formReset();
      this._use.showMsg('success', 'Category Added', 'Added to list Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Adding Fail', 'Unable to Add New Category');
    });
  }

  putCategory() {
    this.category.muserid = this._use.active.id; this.category.mdate = new Date();
    this.api.put('Category/Put/'+this.category.id, this.category).subscribe((data: any) => {
      this.getAllCategories(); this.formReset();
      this._use.showMsg('success', 'Category Updated', 'Details Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this._use.showMsg('error', 'Updated Fail', 'Unable to Update Details');
    });
  }

  Export() {
    this._exp.exportAsExcelFile(this.categories, 'categories');
  }

  formReset(form?: NgForm) {
    if (form != null) {
      form.reset();
    } else {
      this.category = {
        id: 0,
        industryid:  null,
        active: true,
        code: null,
        name: '',
      };
      this.edit = false;
    }
  }

  back() {
    this.formReset();
  }


}
